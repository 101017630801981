import { useDispatch } from 'react-redux';
import { useModelChannel } from './model_channel';
import { addDjVotes, replaceDjVotes, removeDjVotes } from '../ducks/djVotes';

export const useDjVoteChannel = () => {
  const dispatch = useDispatch();

  return useModelChannel('DjVoteChannel', {
    add: ({ votes }) => dispatch(addDjVotes(votes)),
    update: ({ votes }) => dispatch(replaceDjVotes(votes)),
    remove: ({ votes }) => dispatch(removeDjVotes(votes)),
  });
};
