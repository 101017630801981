import { useDispatch } from 'react-redux';
import { useModelChannel } from './model_channel';
import {
  addDjPlayQueueItems,
  replaceDjPlayQueueItems,
  removeDjPlayQueueItems,
} from '../ducks/djPlayQueue';

export const useDjPlayQueueItemChannel = () => {
  const dispatch = useDispatch();

  return useModelChannel('DjPlayQueueItemChannel', {
    add: ({ queue_items }) => dispatch(addDjPlayQueueItems(queue_items)),
    update: ({ queue_items }) => dispatch(replaceDjPlayQueueItems(queue_items)),
    remove: ({ queue_items }) => dispatch(removeDjPlayQueueItems(queue_items)),
  });
};
