import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectors as audioAssetSelectors } from '../ducks/sounds';
import { selectors as visualAssetSelectors } from '../ducks/media';

const INTERACTION = 'interaction.start';
const INTERACTION_RESULTS = 'interaction.result';

// INTERACTION TYPES
const TEAM_SCREAM = 'team-scream';
const LIVE_STREAM_START = 'live-stream-start';
const LIVE_STREAM_STOP = 'live-stream-stop';
const HLS_STREAM_START = 'hls-stream-start';
const MEDIA = 'media';
const SOUND_STOP = 'stop-sound';
const DJ = 'dj';
const TRIVIA = 'trivia';
const POLL = 'poll';
const STATS = 'stats';
const SOCIAL = 'webview';
const SCOREBOARD = 'scoreboard';
const STM_ON = 'season-ticket-stream-start';
const STM_OFF = 'season-ticket-stream-stop';

const MEDIA_VIDEO = 'video';
const MEDIA_SOUND = 'sound';
const MEDIA_IMAGE = 'image';

const DEFAULT_RESULTS_TIMEOUT_MS = 10000;

const useInteractionsFeedState = () => {
  const message = useSelector(
    ({ interactionsFeed: { gameTimeMessage } }) => gameTimeMessage
  );

  const [lastEventLabel, setLastEventLabel] = useState('');

  const [currentAudioMedia, setCurrentAudioMedia] = useState(null);
  const [audioTimeData, setAudioTimeData] = useState(null);
  const audioAssets = useSelector(audioAssetSelectors.selectAll);
  const audioPlayer = useRef(null);

  const [currentVisualMedia, setCurrentVisualMedia] = useState(null);
  const [videoTimeData, setVideoTimeData] = useState(null);
  const visualAssets = useSelector(visualAssetSelectors.selectAll);
  const videoPlayer = useRef(null);

  const [hideHeartbeat, setHideHeartbeat] = useState(false);
  const [showSTM, setShowSTM] = useState(false);
  const [lastScoreboardType, setLastScoreboardType] = useState('');

  const timeout = useRef(null);

  const runInteractionTimer = duration => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setHideHeartbeat(false);
    }, duration);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  const replaceCurrentVisual = () => {
    setHideHeartbeat(true);
    setCurrentVisualMedia(null);
    clearTimeout(timeout.current);
  };

  const handleMediaInteraction = data => {
    if (!data.media) {
      return;
    }

    const { media } = data;
    const { mediaType } = media;

    setLastEventLabel('');

    switch (mediaType) {
      case MEDIA_IMAGE:
      case MEDIA_VIDEO:
        replaceCurrentVisual();
        setCurrentVisualMedia(media);
        break;
      case MEDIA_SOUND:
        setCurrentAudioMedia(media);
        break;
      default:
        return;
    }
  };

  const handleInteraction = data => {
    switch (data.interactionType) {
      case LIVE_STREAM_START:
        setLastEventLabel('Live Stream Start');
        break;
      case LIVE_STREAM_STOP:
        setLastEventLabel('Heartbeat');
        setHideHeartbeat(false);
        setCurrentVisualMedia(null);
        setCurrentAudioMedia(null);
        clearTimeout(timeout.current);
        break;
      case HLS_STREAM_START:
        setLastEventLabel('HLS Stream Start');
        replaceCurrentVisual();
        break;
      case SOUND_STOP:
        setCurrentAudioMedia(null);
        break;
      case MEDIA:
        handleMediaInteraction(data);
        break;
      case TEAM_SCREAM:
        setLastEventLabel('Team Scream Start');
        replaceCurrentVisual();
        break;
      case TRIVIA:
        setLastEventLabel(`Trivia: ${data.metadata.title}`);
        replaceCurrentVisual();
        break;
      case POLL:
        setLastEventLabel(`Poll: ${data.metadata.title}`);
        replaceCurrentVisual();
        break;
      case DJ:
        setLastEventLabel(`DJ: ${data.metadata.djRequestId}`);
        replaceCurrentVisual();
        break;
      case STATS:
        setLastEventLabel(data.statKey);
        replaceCurrentVisual();
        break;
      case SOCIAL:
        setLastEventLabel('Social Wall');
        replaceCurrentVisual();
        break;
      case SCOREBOARD:
        setLastEventLabel(`${data.metadata.contentType} Scoreboard`);
        setLastScoreboardType(data.metadata.contentType);
        replaceCurrentVisual();
        break;
      case STM_ON:
        setShowSTM(true);
        break;
      case STM_OFF:
        setShowSTM(false);
        break;
      default:
        setLastEventLabel(data.interactionType);
    }
  };

  const handleInteractionResults = data => {
    replaceCurrentVisual();
    switch (data.interactionType) {
      case TEAM_SCREAM:
        setLastEventLabel('Team Scream Results');
        runInteractionTimer(DEFAULT_RESULTS_TIMEOUT_MS);
        break;
      case TRIVIA:
        setLastEventLabel('Trivia Results');
        runInteractionTimer(DEFAULT_RESULTS_TIMEOUT_MS);
        break;
      case POLL:
        setLastEventLabel('Poll Results');
        runInteractionTimer(DEFAULT_RESULTS_TIMEOUT_MS);
        break;
      case DJ:
        setLastEventLabel('DJ Results');
        runInteractionTimer(DEFAULT_RESULTS_TIMEOUT_MS);
        break;
      default:
        setLastEventLabel(data.interactionType);
    }
  };

  useEffect(() => {
    if (message == null) {
      return;
    }

    console.log(`interaction message via pusher`);
    console.dir(message);
    const { event, data } = message;

    switch (event) {
      case INTERACTION:
        handleInteraction(data);
        break;
      case INTERACTION_RESULTS:
        handleInteractionResults(data);
        break;
      default:
        return;
    }
  }, [message]);

  useEffect(() => {
    const onTimeUpdate = ({ target: { currentTime, duration } }) => {
      setAudioTimeData({
        currentTime,
        duration,
      });
    };
    const onEnded = () => {
      if (!audioPlayer.current.loop) {
        setCurrentAudioMedia(null);
      }
    };
    audioPlayer.current.addEventListener('timeupdate', onTimeUpdate);
    audioPlayer.current.addEventListener('ended', onEnded);

    const player = audioPlayer.current;
    return () => {
      player.removeEventListener('timeupdate', onTimeUpdate);
      player.removeEventListener('ended', onEnded);
    };
  }, []);

  useEffect(() => {
    const onTimeUpdate = ({ target: { currentTime, duration } }) => {
      setVideoTimeData({
        currentTime,
        duration,
      });
    };
    const onEnded = () => {
      if (!videoPlayer.current.loop) {
        setHideHeartbeat(false);
        setCurrentVisualMedia(null);
      }
    };

    videoPlayer.current.addEventListener('timeupdate', onTimeUpdate);
    videoPlayer.current.addEventListener('ended', onEnded);
    const player = videoPlayer.current;
    return () => {
      player.removeEventListener('timeupdate', onTimeUpdate);
      player.addEventListener('ended', onEnded);
    };
  }, []);

  useEffect(() => {
    if (currentVisualMedia === null) {
      videoPlayer.current.pause();
      videoPlayer.current.src = '';
      videoPlayer.current.load();
    }
  }, [currentVisualMedia]);

  const showAudioTimeData = currentAudioMedia && audioTimeData;
  const audioAsset =
    currentAudioMedia &&
    audioAssets.find(({ id }) => id == currentAudioMedia.id);
  const audioAssetSrc = audioAsset && audioAsset.asset_url;
  const audioAssetTitle = audioAsset && audioAsset.title;
  const repeatAudio = currentAudioMedia && currentAudioMedia.repeat;

  const visualAsset =
    currentVisualMedia &&
    visualAssets.find(({ id }) => id == currentVisualMedia.id);
  const visualAssetTitle = visualAsset && visualAsset.title;
  const visualAssetSrc = visualAsset && visualAsset.asset_url;
  const repeatVideo = currentVisualMedia && currentVisualMedia.repeat;
  const showImage = visualAsset && visualAsset.asset_type.includes('image');
  const showVideoTimeData = currentVisualMedia && !showImage && videoTimeData;
  const showHeartbeat =
    !hideHeartbeat &&
    (lastEventLabel === 'Heartbeat' || lastScoreboardType.length === 0);
  const showCachedScoreboard =
    lastScoreboardType.length > 0 &&
    !hideHeartbeat &&
    lastEventLabel !== 'Heartbeat';

  return {
    lastEventLabel,
    visualAssetTitle,
    visualAssetSrc,
    repeatVideo,
    showImage,
    showVideoTimeData,
    videoTimeData,
    showHeartbeat,
    showCachedScoreboard,
    audioAssetSrc,
    audioAssetTitle,
    repeatAudio,
    showAudioTimeData,
    audioTimeData,
    audioPlayer,
    videoPlayer,
    showSTM,
    lastScoreboardType,
  };
};

export default useInteractionsFeedState;
