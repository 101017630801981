import { useDispatch } from 'react-redux';
import { useModelChannel } from './model_channel';
import {
  addDjRequests,
  replaceDjRequests,
  removeDjRequests,
} from '../ducks/djRequests';

export const useDjRequestChannel = () => {
  const dispatch = useDispatch();

  return useModelChannel('GameDjRequestChannel', {
    add: ({ requests }) => dispatch(addDjRequests(requests)),
    update: ({ requests }) => dispatch(replaceDjRequests(requests)),
    remove: ({ requests }) => dispatch(removeDjRequests(requests)),
  });
};
