import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../stylesheets/DjPlayQueue.scss';
import { selectors as djSongSelectors } from '../ducks/djSongs';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  selectors as djPlayQueueItemSelectors,
  markItemPlayed,
} from '../ducks/djPlayQueue';
import CheckIcon from '@material-ui/icons/Check';

const DjPlayQueue = () => {
  const dispatch = useDispatch();
  const djPlayQueue = useSelector(djPlayQueueItemSelectors.selectAll);
  const djSongs = useSelector(djSongSelectors.selectAll);

  const playlist = djPlayQueue.map(item => {
    const song = djSongs.find(s => s.id === item.team_dj_song_id);
    return {
      ...item,
      song_name: song.song_name,
      artist_name: song.artist_name,
    };
  });

  const handleMarkPlayed = itemId => {
    dispatch(markItemPlayed({ item_id: itemId }))
      .then(unwrapResult)
      .catch(error => {
        console.error(error);
        showErrorMessage();
      });
  };

  const showErrorMessage = () => {
    alert('Failed to mark song as played...Please try again!');
  };

  const playedAt = item => {
    const playedAt = item.played_at;
    const tempDate = new Date(playedAt);
    return tempDate.toLocaleTimeString();
  };

  return (
    <div className="dj-queue-container">
      <div className="song-list">
        <table>
          <thead>
            <tr>
              <th scope="col"></th>
              <th className="song" scope="col">
                Song
              </th>
              <th className="artist" scope="col">
                Artist
              </th>
              <th className="played_at" scope="col">
                Played
              </th>
            </tr>
          </thead>
          <tbody>
            {playlist.map((s, index) => (
              <tr key={s.id}>
                <td>{index + 1}</td>
                <td className="song">{s.song_name}</td>
                <td className="artist">{s.artist_name}</td>
                <td className="played_at">
                  {s.played_at ? (
                    <span>{playedAt(s)}</span>
                  ) : (
                    <button
                      className="mark-played-button"
                      onClick={() => handleMarkPlayed(s.id)}
                    >
                      <CheckIcon />
                      Mark Played
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DjPlayQueue;
