import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import '../stylesheets/CreateDjRequestModal.scss';
import { selectors as djSongSelectors } from '../ducks/djSongs';
import { unwrapResult } from '@reduxjs/toolkit';
import { createGameDjRequest } from '../ducks/djRequests';
import DjSongRow from './DjSongRow';

const CreateDjRequestModal = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const djSongs = useSelector(djSongSelectors.selectAll);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState();
  const [selectedSongs, setSelectedSongs] = useState([]);
  const [draggingSong, setDraggingSong] = useState();

  const submit = () => {
    if (validateForm()) {
      dispatch(
        createGameDjRequest({
          songs: selectedSongs,
        })
      )
        .then(unwrapResult)
        .then(() => {
          handleClose();
          resetForm();
        })
        .catch(error => {
          console.error(error);
          showErrorMessage();
        });
    } else {
      alert('Please fill out all details for the form.');
    }
  };

  const validateForm = () => {
    return selectedSongs && selectedSongs.length > 0;
  };

  const resetForm = () => {
    setSelectedSongs([]);
  };

  const showErrorMessage = () => {
    alert('DJ Request creation failed... Please try again!');
  };

  const addSong = song => {
    const songs = [...selectedSongs];
    songs.push(song);
    setSelectedSongs(songs);
    setSearchQuery('');
  };

  const removeSong = song => {
    const songs = [...selectedSongs.filter(s => s.id != song.id)];
    setSelectedSongs(songs);
  };

  useEffect(() => {
    if (!searchQuery || searchQuery.trim().length == 0) {
      setSearchResults([]);
      return;
    }
    const query = searchQuery.toLowerCase();
    const results = djSongs
      .filter(s => {
        return (
          s.artist_name.toLowerCase().includes(query) ||
          s.song_name.toLowerCase().includes(query)
        );
      })
      .filter(s => !selectedSongs.includes(s));
    setSearchResults(results);
  }, [searchQuery, djSongs, selectedSongs]);

  const handleDragStart = (e, song) => {
    setDraggingSong(song);
    e.dataTransfer.setData('text/plain', '');
  };

  // eslint-disable-next-line no-unused-vars
  const handleDragEnd = e => {
    setDraggingSong(null);
  };

  const handleDrop = (e, targetSong) => {
    if (!draggingSong) return;

    const currentIndex = selectedSongs.indexOf(draggingSong);
    const targetIndex = selectedSongs.indexOf(targetSong);

    if (currentIndex !== -1 && targetIndex !== -1) {
      const songs = selectedSongs;
      songs.splice(currentIndex, 1);
      songs.splice(targetIndex, 0, draggingSong);
      setSelectedSongs(...[songs]);
    }
  };

  return (
    <Modal id="dj-request" show={show} size="lg" centered onHide={() => {}}>
      <div className="modal-dj-request-form">
        <h3>Create DJ Request</h3>
        <div className="search-container">
          <div className="input-group">
            <input
              className="search-bar"
              placeholder="Search for"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              onKeyDown={e => {
                console.log(`key = ${e.key}`);
                console.log(e);
              }}
            />
            <SearchIcon className="search-icon" />
          </div>
          {searchResults && searchResults.length > 0 && (
            <ul
              id="autocomplete-list"
              className="song-results-list"
              role="listbox"
            >
              {searchResults.map((result, index) => (
                <li key={index} onClick={() => addSong(result)} role="option">
                  <span className="song-name">{result.song_name}</span>
                  <span className="artist-name">({result.artist_name})</span>
                </li>
              ))}
            </ul>
          )}
        </div>
        <h3>Selected Songs</h3>
        <div className="options-list">
          {selectedSongs.map((song, index) => {
            return (
              <DjSongRow
                song={song}
                key={index}
                i={index}
                onRemove={() => removeSong(song)}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDrop={handleDrop}
              />
            );
          })}
        </div>
        <div className="dj-actions">
          <Button className="create-dj-request" onClick={submit}>
            Create DJ Request
          </Button>
          <Button
            className="cancel-dj-request"
            onClick={() => {
              handleClose();
              resetForm();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateDjRequestModal;
