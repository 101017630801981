// Load all the channels within this directory and all subdirectories.
// Channel files must be named *_channel.js.

import { useMediaChannel } from './media_channel';
import { useCategoryChannel } from './category_channel';
import { useTeamConfigurationChannel } from './team_configuration_channel';
import { usePollChannel } from './poll_channel';
import { useTriviaChannel } from './trivia_channel';
import { useSoundChannel } from './sound_channel';
import { useStatisticChannel } from './statistic_channel';
import { useInteractionChannel } from './interaction_channel';
import { useGameInstanceChannel } from './game_instance_channel';
import { useDjRequestChannel } from './game_dj_request_channel';
import { useDjSongChannel } from './dj_song_channel';
import { useDjVoteChannel } from './dj_vote_channel';
import { useDjPlayQueueItemChannel } from './dj_play_queue_item_channel';

export const useChannels = () => {
  useMediaChannel();
  useCategoryChannel();
  useTeamConfigurationChannel();
  usePollChannel();
  useTriviaChannel();
  useDjRequestChannel();
  useDjSongChannel();
  useDjVoteChannel();
  useDjPlayQueueItemChannel();
  useSoundChannel();
  useStatisticChannel();
  useInteractionChannel();
  useGameInstanceChannel();
};
