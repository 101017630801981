import React from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useDispatch, useSelector } from 'react-redux';
import '../stylesheets/DjVoteResults.scss';
import { selectors as djVoteSelectors } from '../ducks/djVotes';
import { selectors as djSongSelectors } from '../ducks/djSongs';
import { createDjPlayQueueItem } from '../ducks/djPlayQueue';
import { sendNotification } from '../ducks/interactions';

const DjVoteResults = ({ gameInstanceId }) => {
  const dispatch = useDispatch();
  const djVotes = useSelector(djVoteSelectors.selectAll);
  const djSongs = useSelector(djSongSelectors.selectAll);

  const totalVotes = djVotes.reduce((acc, vote) => acc + vote.votes, 0);

  const voteResults = djSongs
    .map(s => {
      const votes = djVotes.filter(v => v.team_dj_song_id === s.id);
      const totalSongVotes = votes.reduce((acc, vote) => acc + vote.votes, 0);

      return {
        ...s,
        votes: totalSongVotes,
        percentage: Math.round((totalSongVotes / totalVotes) * 100),
      };
    })
    .filter(result => result.votes > 0)
    .sort((a, b) => b.votes - a.votes);

  const addSongToQueue = song => {
    dispatch(
      createDjPlayQueueItem({
        song_id: song.id,
      })
    )
      .then(() => {
        return dispatch(
          sendNotification({
            gameInstanceId,
            notification: {
              interactionType: 'dj',
              title: 'Song playing soon!',
              body: `${song.song_name} will be played soon!`,
            },
          })
        );
      })
      .catch(error => {
        console.error(error);
        alert('Could not add song to playlist... Please try again!');
      });
  };

  return (
    <div className="dj-results-container">
      <div className="song-list">
        <table>
          <thead>
            <tr>
              <th scope="col"></th>
              <th className="song" scope="col">
                Song
              </th>
              <th className="artist" scope="col">
                Artist
              </th>
              <th className="votes" scope="col">
                Votes
              </th>
              <th className="percentage" scope="col">
                %
              </th>
              <th className="actions"></th>
            </tr>
          </thead>
          <tbody>
            {voteResults.map((s, index) => (
              <tr key={s.id}>
                <td>{index + 1}</td>
                <td className="song">{s.song_name}</td>
                <td className="artist">{s.artist_name}</td>
                <td className="votes">{s.votes}</td>
                <td className="percentage">{s.percentage}</td>
                <td className="actions">
                  <button
                    className="add-playlist-button"
                    onClick={() => addSongToQueue(s)}
                  >
                    <AddCircleIcon />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DjVoteResults;
