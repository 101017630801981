import React from 'react';
import '../stylesheets/DjSongRow.scss';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import Button from 'react-bootstrap/Button';

const DjSongRow = ({ song, onRemove, onDragStart, onDragEnd, onDrop }) => {
  const handleDragOver = e => {
    e.preventDefault();
  };
  return (
    <div
      className="dj-song-row"
      draggable={true}
      onDragStart={e => onDragStart(e, song)}
      onDragOver={handleDragOver}
      onDragEnd={onDragEnd}
      onDrop={e => onDrop(e, song)}
    >
      <DragHandleIcon className="dj-song-drag" />
      <div className="song-info">
        <span className="song-name">{song.song_name}</span>
        <span className="artist-name">{song.artist_name}</span>
      </div>
      <Button className="remove-option" onClick={onRemove}>
        Remove
      </Button>
    </div>
  );
};

export default DjSongRow;
