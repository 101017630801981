import { useDispatch } from 'react-redux';
import { useModelChannel } from './model_channel';
import { addDjSongs, replaceDjSongs, removeDjSongs } from '../ducks/djSongs';

export const useDjSongChannel = () => {
  const dispatch = useDispatch();

  return useModelChannel('TeamDjSongChannel', {
    add: ({ songs }) => dispatch(addDjSongs(songs)),
    update: ({ songs }) => dispatch(replaceDjSongs(songs)),
    remove: ({ songs }) => dispatch(removeDjSongs(songs)),
  });
};
