import { createSlice } from '@reduxjs/toolkit';
import { EntityAdapter } from './_util';

const adapter = new EntityAdapter();

const slice = createSlice({
  name: 'djSongs',
  initialState: {
    ...adapter.getInitialState(),
  },
  reducers: {
    addDjSongs: adapter.addMany,
    replaceDjSongs: adapter.replaceMany,
    removeDjSongs: adapter.removeMany,
  },
});

export const { addDjSongs, replaceDjSongs, removeDjSongs } = slice.actions;

export const selectors = adapter.getSelectors(slice.name);

export default slice.reducer;
