import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Pusher from 'pusher-js';
import {
  setConnectionState,
  setGameTimeMessage,
} from '../ducks/interactionsFeed';
import { handleInteractionResult } from '../ducks/interactions';
import { useSessionState } from '../ducks/session';
import { toByteArray } from 'base64-js';
import decompress from 'brotli/decompress';

const GAME_TIME_CHANNEL_NAME = 'game-time@';
const SEASON_TICKET_CHANNEL_NAME = 'season-ticket-game@';

const PusherClient = () => {
  console.log('starting PusherClient');
  const dispatch = useDispatch();
  const {
    gameId,
    teamId,
    config: { pusherKey, pusherCluster },
  } = useSessionState();
  useEffect(() => {
    const client = Client.create({
      pusherKey,
      pusherCluster,
      gameId,
      teamId,
      dispatch,
    });

    return client.stop;
  });

  return null;
};

class Client {
  static create(config) {
    const client = new Client(config);
    client.start();
    return client;
  }

  constructor({ pusherKey, pusherCluster, teamId, gameId, dispatch }) {
    this.gameTimeChannel = null;
    this.seasonTicketChannel = null;
    this.client = null;
    Object.assign(this, { pusherKey, pusherCluster, teamId, gameId, dispatch });
  }

  start = () => {
    this.client = new Pusher(this.pusherKey, {
      cluster: this.pusherCluster,
      forceTLS: true,
    });
    this.client.connection.bind('state_change', this.onStateChange);
  };

  stop = () => {
    this.channels.forEach(channel => channel.stop());
    this.client.disconnect();
  };

  onStateChange = states => {
    this.dispatch(setConnectionState(states.current));
    if (states.current === 'connected' && this.channels == null) {
      this.channels = [
        Channel.create(
          `${GAME_TIME_CHANNEL_NAME}${this.gameId};${this.teamId}`,
          this
        ),
        Channel.create(
          `${SEASON_TICKET_CHANNEL_NAME}${this.gameId};${this.teamId}`,
          this
        ),
      ];
    }
  };

  onEvent = (event, data) => {
    console.log('Pusher event');
    // console.dir(event);
    // console.dir(Client.decompressData(data));
    this.dispatch(
      setGameTimeMessage({ event, data: Client.decompressData(data) })
    );
    if (event === 'interaction.result') {
      handleInteractionResult(this.dispatch, event, data);
    }
  };
  subscribe = channelName => this.client.subscribe(channelName);
  unsubscribe = channelName => this.client.unsubscribe(channelName);

  static decompressData = data => {
    if (data.compressed) {
      return JSON.parse(
        String.fromCharCode.apply(
          null,
          decompress(toByteArray(data.compressed))
        )
      );
    }
    return data;
  };
}

class Channel {
  static create(name, client) {
    const channel = new Channel(name, client);
    channel.start();
    return channel;
  }

  constructor(name, client) {
    this.name = name;
    this.client = client;
    this.channel = null;
  }

  start() {
    this.channel = this.client.subscribe(this.name);
    this.channel.unbind_all();
    this.channel.bind_global(this.client.onEvent);
  }

  stop() {
    this.client.unsubscribe(this.name);
    this.channel.unbind_all();
  }
}

export default PusherClient;
