import { post } from '../services/cms_api_client';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AsyncStatus } from './_util';
import { saveDjResult } from './djRequests';
import { useSelector } from 'react-redux';

export const triggerInteraction = createAsyncThunk(
  'interactions/trigger',
  ({ gameInstanceId, interactionInfo }, { dispatch, requestId }) => {
    return post(
      `/api/interaction/trigger`,
      createInteractionRequest(gameInstanceId, interactionInfo)
    ).catch(error => {
      dispatch(
        setInteractionDetails({
          error: error.message || 'error triggering interaction',
          snackbarPreview: interactionInfo.snackbarPreview,
          id: requestId,
        })
      );
      throw error;
    });
  },
  {
    condition: (_, { getState }) =>
      getState().interactions.triggerInteractionStatus !== AsyncStatus.Pending,
  }
);

export const sendNotification = createAsyncThunk(
  'interactions/send_notification',
  ({ gameInstanceId, notification }, { getState }) => {
    const {
      session: { teamId },
    } = getState();
    console.log(`sending notification for game ${gameInstanceId}`);
    console.dir(notification);
    return post(`/api/interaction/send_notification`, {
      gameId: gameInstanceId,
      teamId: teamId,
      ...notification,
    });
  }
);

const slice = createSlice({
  name: 'interactions',
  initialState: {
    triggerInteractionStatus: AsyncStatus.Fulfilled,
    interactionDetails: {},
  },
  reducers: {
    setInteractionDetails: (
      state,
      { payload: { error, title, id, snackbarPreview, interactionType } }
    ) => {
      state.interactionDetails = {
        error,
        title,
        id,
        snackbarPreview,
        interactionType,
      };
    },
  },
  extraReducers: {
    [triggerInteraction.pending]: state => {
      state.triggerInteractionStatus = AsyncStatus.Pending;
    },
    [triggerInteraction.fulfilled]: state => {
      state.triggerInteractionStatus = AsyncStatus.Fulfilled;
    },
    [triggerInteraction.rejected]: state => {
      state.triggerInteractionStatus = AsyncStatus.Rejected;
    },
  },
});

const { setInteractionDetails } = slice.actions;

export const useInteractions = () =>
  useSelector(({ interactions: { interactionDetails } }) => interactionDetails);

export const useTriggerInteractionStatus = () =>
  useSelector(
    ({ interactions: { triggerInteractionStatus } }) => triggerInteractionStatus
  );

export default slice.reducer;

export const interactionSent = ({
  id,
  interaction,
  metadata = {},
}) => dispatch => {
  dispatch(
    setInteractionDetails({ id, interactionType: interaction, ...metadata })
  );
};

const createInteractionRequest = (gameInstanceId, interactionInfo) => {
  const interactionType = interactionInfo.type;
  const request = {
    gameInstanceId,
    metadata: {},
    type: interactionType,
  };

  // add mediaId if this is a media
  if (interactionType === 'media') {
    const {
      mediaId,
      mediaContentType,
      over21,
      isRepeating,
      snackbarPreview,
    } = interactionInfo;

    request.metadata.mediaId = mediaId;
    request.metadata.mediaContentType = mediaContentType;
    request.metadata.over21 = over21;
    request.metadata.repeat = isRepeating;
    request.metadata.snackbarPreview = snackbarPreview;
  }

  // add trivia metadata if this is a trivia interaction
  if (interactionType === 'trivia') {
    const { triviaId, question, options } = interactionInfo;

    request.metadata.triviaId = triviaId;
    request.metadata.question = question;

    // the JSON blob for options has one property 'options'
    // that is an array of options... so just removing a layer here
    // when sending to the main API
    request.metadata.options = options;
  }

  // add poll metadata if this is a poll interaction
  if (interactionType === 'poll') {
    const { pollId, question, options } = interactionInfo;

    request.metadata.pollId = pollId;
    request.metadata.question = question;

    // the JSON blob for options has one property 'options'
    // that is an array of options... so just removing a layer here
    // when sending to the main API
    request.metadata.options = options;
  }

  // add poll metadata if this is a poll interaction
  if (interactionType === 'poll') {
    const { pollId, question, options } = interactionInfo;

    request.metadata.pollId = pollId;
    request.metadata.question = question;

    // the JSON blob for options has one property 'options'
    // that is an array of options... so just removing a layer here
    // when sending to the main API
    request.metadata.options = options;
  }

  // add dj metadata if this is a dj interaction
  if (interactionType === 'dj') {
    console.log(`createInteractionRequest`);
    console.dir(interactionInfo);
    const { djRequestId, songs, entries } = interactionInfo;

    request.metadata.djRequestId = djRequestId;
    request.metadata.songs = songs;
    request.metadata.entries = entries;
  }

  // add stat metadata if this is a stat interaction
  if (interactionType === 'stats') {
    const { statKey, options, statQueryMeta } = interactionInfo;

    request.metadata = { statKey, statQueryMeta, options };
  }

  if (
    ['webview', 'hls-stream-start', 'season-ticket-stream-start'].includes(
      interactionType
    )
  ) {
    const { url } = interactionInfo;
    request.metadata = { url };
  }

  request.metadata.title = interactionInfo.title;

  return request;
};

export const handleInteractionResult = (dispatch, event, data) => {
  console.log('handleInteractionResult');
  console.log(event);
  console.dir(data);
  if (data.interactionType === 'dj') {
    console.log('handling DJ interaction results');

    // console.log(data.options);
    // console.log(data.metadata);

    // const voteMap = new Map();

    // data.options.forEach(o => {
    //   const entry = data.metadata.entries.find(e => e.team_dj_song_id === o.id);
    //   voteMap.set(entry.id, o.votes);
    // });

    // const djRequestSongs = [];
    // for (const entry of data.metadata.entries) {
    //   const votes = voteMap.get(entry.id);
    //   djRequestSongs.push({
    //     id: entry.id,
    //     votes: votes,
    //   });
    // }

    // console.dir(djRequestSongs);

    // const updates = djRequestSongs.map(e => {
    //   console.log(`dispatching dj request update ${e.id}`);
    //   return dispatch(updateDjRequestSong({ dj_request_song: e }));
    // });

    dispatch(saveDjResult({ result: data }))
      .then(console.log(`Save DJ Result completed`))
      .catch(e => console.error(e));
  }
};
