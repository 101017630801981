import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { EntityAdapter, AsyncStatus } from './_util';
import { post } from '../services/cms_api_client';

export const createGameDjRequest = createAsyncThunk(
  'game_dj_request/create',
  ({ songs }, { getState }) => {
    const {
      session: { gameInstanceId, teamId },
    } = getState();
    return post(`/admin/game_dj_request/new.js`, {
      game_dj_request: {
        game_instance_id: gameInstanceId,
        team_id: teamId,
        dj_request_songs_attributes: songs.map((song, index) => ({
          team_dj_song_id: song.id,
          sort_order: index,
        })),
      },
    });
  }
);

export const saveDjResult = createAsyncThunk(
  'game_dj_result/save',
  ({ result }, { getState }) => {
    const {
      session: { gameInstanceId, teamId },
    } = getState();
    console.log('saveDjResult');
    console.log(result);
    return post(
      `/api/interaction/save_dj_result`,
      createSaveDjResultRequest(result, gameInstanceId, teamId)
    );
  }
);

const createSaveDjResultRequest = (result, gameInstanceId, teamId) => {
  console.log('createSaveDjResultRequest');
  const entries = result.options.map(e => {
    return {
      dj_request_song_id: e.dj_request_song_id,
      team_dj_song_id: e.id,
      votes: e.votes,
    };
  });

  const saveRequest = {
    dj_request_id: result.metadata.djRequestId,
    interaction_id: result.interactionId,
    game_id: gameInstanceId,
    team_id: teamId,
    entries: entries,
  };
  console.log(saveRequest);
  return saveRequest;
};

const adapter = new EntityAdapter();

const slice = createSlice({
  name: 'djRequests',
  initialState: {
    ...adapter.getInitialState(),
    createGameDjRequestStatus: AsyncStatus.Fulfilled,
  },
  reducers: {
    addDjRequests: adapter.addMany,
    replaceDjRequests: adapter.replaceMany,
    removeDjRequests: adapter.removeMany,
  },
  extraReducers: {
    [createGameDjRequest.pending]: state => {
      state.createGameDjRequestStatus = AsyncStatus.Pending;
    },
    [createGameDjRequest.fulfilled]: state => {
      state.createGameDjRequestStatus = AsyncStatus.Fulfilled;
    },
    [createGameDjRequest.rejected]: state => {
      state.createGameDjRequestStatus = AsyncStatus.Rejected;
    },
  },
});

export const {
  addDjRequests,
  replaceDjRequests,
  removeDjRequests,
} = slice.actions;

export const selectors = adapter.getSelectors(slice.name);

export default slice.reducer;
