import { createSlice } from '@reduxjs/toolkit';
import { EntityAdapter } from './_util';

const adapter = new EntityAdapter();

const slice = createSlice({
  name: 'djVotes',
  initialState: {
    ...adapter.getInitialState(),
  },
  reducers: {
    addDjVotes: adapter.addMany,
    replaceDjVotes: adapter.replaceMany,
    removeDjVotes: adapter.removeMany,
  },
});

export const { addDjVotes, replaceDjVotes, removeDjVotes } = slice.actions;

export const selectors = adapter.getSelectors(slice.name);

export default slice.reducer;
