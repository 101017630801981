import React, { useState } from 'react';
import '../stylesheets/DjRequestListRow.scss';
import ConfirmModal from './ConfirmModal';
import { useTeamConfiguration } from '../ducks/teamConfiguration';
import SendButton from './SendButton';

const DjRequestListRow = ({ djRequest, onSend }) => {
  const [confirmationPresented, setConfirmationPresented] = useState(false);

  const presentConfirmation = () => setConfirmationPresented(true);
  const dismissConfirmation = () => setConfirmationPresented(false);

  const requestSongs = djRequest.dj_request_songs.map(req => {
    const djSong = djRequest.team_dj_songs.find(
      s => s.id === req.team_dj_song_id
    );

    return {
      ...req,
      song_name: djSong.song_name,
      artist_name: djSong.artist_name,
    };
  });

  const teamConfig = useTeamConfiguration();

  const teamRequiresConfirmation = () => {
    return teamConfig.confirm_before_sending;
  };

  const lastInteractionAt = () => {
    const lastInteraction = djRequest.last_interaction_at;
    const tempDate = new Date(lastInteraction);
    return tempDate.toLocaleTimeString();
  };

  return (
    <div className="dj-request-container">
      <ConfirmModal
        title="Confirm Interaction"
        confirmButtonTitle="Trigger Interaction"
        onConfirm={() => {
          dismissConfirmation();
          onSend();
        }}
        show={confirmationPresented}
        handleClose={dismissConfirmation}
      />
      <div className="song-list">
        <table>
          <thead>
            <tr>
              <th scope="col"></th>
              <th className="song" scope="col">
                Song
              </th>
              <th className="artist" scope="col">
                Artist
              </th>
              <th className="votes" scope="col">
                Votes
              </th>
            </tr>
          </thead>
          <tbody>
            {requestSongs.map(s => (
              <tr key={s.id}>
                <td>{s.sort_order + 1}</td>
                <td className="song">{s.song_name}</td>
                <td className="artist">{s.artist_name}</td>
                <td className="votes">{s.votes}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        {djRequest.last_interaction_at && (
          <div className="previously-dispatched">
            <div className="details-text-box">
              <p>Sent: {lastInteractionAt()}</p>
            </div>
          </div>
        )}
      </div>
      <div className="input-container">
        <div className="send-button-container">
          <SendButton
            onSend={() => {
              teamRequiresConfirmation() ? presentConfirmation() : onSend();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DjRequestListRow;
