import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { EntityAdapter, AsyncStatus } from './_util';
import { post } from '../services/cms_api_client';

export const createDjPlayQueueItem = createAsyncThunk(
  'dj_play_queue_item/create',
  ({ song_id }, { getState }) => {
    const {
      session: { gameInstanceId, teamId },
    } = getState();
    return post(`/admin/dj_play_queue_item/new.js`, {
      dj_play_queue_item: {
        game_instance_id: gameInstanceId,
        team_id: teamId,
        team_dj_song_id: song_id,
      },
    });
  }
);

export const markItemPlayed = createAsyncThunk(
  'dj_play_queue_item/mark_played',
  ({ item_id }) => {
    return post(`/api/interaction/mark_dj_song_played.js`, {
      id: item_id,
    });
  }
);

const adapter = new EntityAdapter();

const slice = createSlice({
  name: 'djPlayQueueItems',
  initialState: {
    ...adapter.getInitialState(),
    createDjPlayQueueItemStatus: AsyncStatus.Fulfilled,
  },
  reducers: {
    addDjPlayQueueItems: adapter.addMany,
    replaceDjPlayQueueItems: adapter.replaceMany,
    removeDjPlayQueueItems: adapter.removeMany,
  },
  extraReducers: {
    [createDjPlayQueueItem.pending]: state => {
      state.createGameDjRequestStatus = AsyncStatus.Pending;
    },
    [createDjPlayQueueItem.fulfilled]: state => {
      state.createGameDjRequestStatus = AsyncStatus.Fulfilled;
    },
    [createDjPlayQueueItem.rejected]: state => {
      state.createGameDjRequestStatus = AsyncStatus.Rejected;
    },
  },
});

export const {
  addDjPlayQueueItems,
  replaceDjPlayQueueItems,
  removeDjPlayQueueItems,
} = slice.actions;

export const selectors = adapter.getSelectors(slice.name);

export default slice.reducer;
